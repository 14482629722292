import axios from "axios";
import { showToast, showLoadingToast, closeToast } from "vant";
let service = axios.create({
  // baseURL: "http://localhost:8080/api/in/wx",
  baseURL: 'https://ypt.inrunonline.com/api/in/wx',
  timeout: 90000,
});
service.interceptors.request.use(
  config => {
    showLoadingToast({
      duration: 0,
      forbidClick: true,
    });
    return config;
  });
// 在请求之后
service.interceptors.response.use((res) => {
  // console.log(res.data);
  closeToast();
  const { code, result, message } = res.data;

  //   根据后端协商 视情况而定
  if (code == 200) {
    return result;
  } else {
    showToast({
      message: message,
      wordBreak: "break-word",
      className: "noticeWidth",
    });
    return res.data;
  }
});
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve) => {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        if (res) {
          resolve(res.data);
        }
        // else if (url == '/admin/user/forgotPasswordSendMail') {
        //   resolve(res.data);
        // }
      });
  });
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
  return new Promise((resolve) => {
    let params2 = params;
    axios.post(url, params2).then((res) => {
      if (res != undefined) {
        resolve(res);
      }
    });
  });
}
export default service;
