<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  components: {
    
  }
}
</script>

<style>
body {
  margin: 0px;
}

#app {
  min-height: 100vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(198deg, #EAF5F7 0%, #FCF9F9 100%);
}
.register {
  width: 90%;
  padding: 0 20px;

}
.header_tt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #1A1B1E;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
}
.cont_c{
  width: 98%;
  height: 156px;
  background: #FFFFFF;
  box-shadow: 0px 12px 30px 1px rgba(177,186,201,0.2);
  border-radius: 10px;
  opacity: 1;
  margin: 12px auto 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 31px;
  box-sizing: border-box;
}
.cont_c div{
  text-align: center;
  font-size: 13px;
  font-family: Source Han Sans CN-Normal, Source Han Sans CN;
  font-weight: 400;
  color: #000000;
}
.cont_c .img{
  width: 54px;
  height: 54px;
  display: block;
  margin: 0 auto 13px;
}
.cont_c .active{
  color: rgba(17, 100, 251, 1);
}
.cont_c text{
  width: 62px;
  height: 5px;
  background: #ECEFF1;
  opacity: 1;
  margin-bottom: 30px;
}
.flex_c{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex_a{
  display: inline-flex;
  align-items: center;
}
.noticeWidth {
  min-width: 200px!important;  
}
</style>
