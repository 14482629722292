import { createRouter, createWebHistory } from 'vue-router'
import Home from '../view/index.vue'
const routes = [
    {
        path: '/',
        name: 'Index',
        component: Home
    },
    {
      path: '/card/:imei',
      name: 'card',
      component: () => import('../view/card.vue')
    },
    {
      path: '/realF/:iccid',
      name: 'realF',
      component: () => import('../view/zyReal.vue')
    },
    {
      path: '/realT',
      name: 'realT',
      component: () => import('../view/real.vue')
    },
    {
      path: '/realDx',
      name: 'realDx',
      component: () => import('../view/realDx.vue')
    },
    {
      path: '/realThree/:url',
      name: 'realThree',
      component: () => import('../view/realThree.vue')
    },
  ]
  
  const router = createRouter({
    history: createWebHistory(),
    routes
  })
  
  export default router