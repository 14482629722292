import service from ".";
export function getByImei(params) {
  return service({
    method: "GEt",
    // url: "/inCard/getByCardId",
    url: "/inCard/getCardReal",
    
    params: params,
  });
}
export function toRealtoReal(params) {
  return service.post("/inDevice/toReal",params)
}
export function ydRealUrl(params) {
  return service({
    method: "GEt",
    url: "/inDevice/ydRealUrl",
    params: params,
  });
}
export function realCheck(params) {
  return service({
    method: "GEt",
    url: "/inDevice/realCheckIccid",
    params: params,
  });
}
