<template>
<div class="header_tt" style="height: 50px;margin-top: 20px;">
    <image src="/images/index3.png" mode="aspectFit"></image>
    <div>实名认证</div>
    <div style="opacity: 0;width: 188rpx;"></div>
</div>
<div class="register">

    <div class="cont_c" style="padding: 0 50px;">
        <div>
            <image src="https://ypt-1255383489.cos.ap-shanghai.myqcloud.com/images/h5/index10.png" class="img"></image>
            <div class="active">标识查询</div>
        </div>
        <text style="width: 100%;"></text>
        <div>
            <image src="https://ypt-1255383489.cos.ap-shanghai.myqcloud.com/images/h5/index7.png" class="img"></image>
            <div>实名认证</div>
        </div>

    </div>
    <div class="imei">
        <input type="text" class="imeiInput" v-model="cardId" placeholder="请输入标识号（ICCID/IMEI/卡片ID）" />
    </div>

    <div class="bindBtn" @click="to">
        下一步
    </div>
</div>
</template>

<script>
import {
    getByImei
} from '../request/api'
import {
    showFailToast
} from "vant"
export default {
    data() {
        return {
            cardId: ""
        }
    },
    methods: {
        to() {
            if (!this.cardId) {
                showFailToast('请输入卡片ID');
                return
            }
            this.$router.push({
                name: 'card',
                params: {
                    imei: this.cardId
                }
            })

        }
    }
}
</script>

<style>
.register {
    width: 90%;
    padding: 0 20px;

}

.imei {
    margin: 20px auto 0;
    position: relative;
    width: 98%;
    height: 62px;
    background: #FFFFFF;
    box-shadow: 0px 12px 30px 1px rgba(177, 186, 201, 0.2);
    border-radius: 10px;
    opacity: 1;
}

.scan {
    z-index: 20;
    position: absolute;
    right: 30rpx;
    bottom: 30rpx;
    color: rgba(17, 100, 251, 1);
}

.imeiInput {
    border: 0;
    outline: none;
    background-color: rgba(0, 0, 0, 0);
    width: 90%;
    height: 62px;
    padding: 0 16px;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: black;
}

.bindBtn {
    width: 286px;
    text-align: center;
    height: 49px;
    line-height: 49px;
    background: #007BFF;
    box-shadow: 0px 10px 20px 1px rgba(0, 123, 255, 0.15);
    border-radius: 10px;
    opacity: 1;
    /* margin: 446rpx auto 0; */
    margin: 70px auto 0;
    font-size: 16px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
}

.titleImg {
    height: 320rpx;
}
</style>
